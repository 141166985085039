import { initializeApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: "AIzaSyAl3MWAzVpOuWTO-nAEkJL-Q85aIcc5CrY",
  authDomain: "gs://blog-fed2c.appspot.com",
  projectId: "estate-e0eee",
  storageBucket: "estate-e0eee.appspot.com",
  messagingSenderId: "407503651638",
  appId: "1:407503651638:web:653ee15d30ca6cb1dea997",
  measurementId: "G-Z81TCK0T34",
};
export const apps = initializeApp(firebaseConfig);
